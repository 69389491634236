import PropTypes from 'prop-types';

function ToastMessage(props) {
  const { message } = props;
  return <span>{message}</span>;
}

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default ToastMessage;
