import PropTypes from 'prop-types';
import { Transition as HeadlessuiTransition } from '@headlessui/react';

function Transition(props) {
  const { children } = props;
  return (
    <HeadlessuiTransition
      show
      appear
      className="h-full"
      enter="transition ease-out duration-50"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-150"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      {children}
    </HeadlessuiTransition>
  );
}
Transition.propTypes = {
  children: PropTypes.node.isRequired
};

export default Transition;
