import { useMemo } from 'react';
import Loader from 'components/Loader';
import SearchInput from './components/SearchInput';
import Viewer from './components/Viewer';
import Modal from './components/Modal';
import useSftpHook from './SFTP.hook';

function SftpView() {
  const { loading, canShowSearchInput } = useSftpHook();

  const Body = useMemo(() => {
    if (loading)
      return (
        <div className="flex w-full h-40 justify-center items-center">
          <Loader />
        </div>
      );

    return (
      <>
        <Viewer />
        <Modal />
      </>
    );
  }, [loading]);

  return (
    <>
      {canShowSearchInput && <SearchInput />}
      {Body}
    </>
  );
}

export default SftpView;
