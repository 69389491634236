import uploadIcon from 'assets/icons/icon-upload.svg';
import useSftpHook from 'views/SFTP/SFTP.hook';
import FileUpload from '../FileUpload';

function calculateProgress(loaded, total) {
  return ((loaded * 100) / total).toFixed(0);
}

function Modal() {
  const { handleUploadFile, showModal, handleShowModal, file, progress } = useSftpHook();
  const { loaded, total } = progress ?? {};
  const isLoading = loaded !== total;
  const calc = loaded && total ? calculateProgress(loaded, total) : 0;
  const progressBarValue = `${calc}%`;

  return (
    <>
      <div className="flex items-center justify-center mt-6 mb-10">
        <button
          className="bg-secondary font-extrabold text-white py-2 px-4 rounded-3xl flex items-center transition-transform hover:scale-105"
          type="button"
          onClick={handleShowModal}>
          <img src={uploadIcon} alt="Upload File" className="mr-4" />
          Upload File
        </button>
      </div>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-[rgba(255,255,255,0.9)]">
            <div className="relative w-full md:w-1/2 my-6 mx-4 md:mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-container outline-none focus:outline-none">
                <div className="relative p-6 flex-auto">
                  <FileUpload />
                </div>
                {isLoading && (
                  <div className="px-6">
                    <div className="w-full bg-neutral rounded-full">
                      <div
                        className="bg-secondary text-xs text-primary font-bold text-center p-1 leading-none rounded-full"
                        style={{ width: progressBarValue }}>
                        {progressBarValue}
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex items-center justify-center p-6 border-slate-100 rounded-b">
                  <button
                    className="background-transparent text-secondary border border-secondary rounded-3xl px-14 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleShowModal}>
                    Cancel
                  </button>

                  {!!file && !isLoading && (
                    <button
                      className={`bg-secondary ml-10 text-white py-2 px-14 rounded-3xl flex items-center ${
                        !file ? 'cursor-not-allowed' : ''
                      }`}
                      type="button"
                      onClick={handleUploadFile}>
                      Upload File
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-content" />
        </>
      )}
    </>
  );
}

export default Modal;
