const getFolderContents = (navigation, pathTree) => {
  let pathWay = pathTree;

  navigation.forEach((folder) => {
    pathWay = pathWay.folders[folder];
  });

  return {
    files: pathWay.files,
    folders: Object.keys(pathWay.folders)
  };
};

const getFileKey = (fileName, navigation, basePath) => {
  const fileKey = [basePath, ...navigation, fileName].join('/');

  return fileKey;
};

const getFolderFileKey = (fileName, navigation, basePath, folder) => {
  const fileKey = [basePath, ...navigation, folder, fileName].join('/');

  return fileKey;
};

const getFilesForFolder = (folder, path, pathTree) => {
  const folderContents =
    pathTree.folders[path[0]]?.folders[path[1]]?.folders[path[2]]?.folders[folder]?.files || [];

  return folderContents;
};

export default {
  getFolderContents,
  getFileKey,
  getFilesForFolder,
  getFolderFileKey
};
