import PropTypes from 'prop-types';
import Transition from 'components/Transition';

function PageContainer(props) {
  const { children, isFullWidth } = props;
  const pageContainerClassname = isFullWidth ? '' : 'container';
  return (
    <div className={`w-full m-auto h-full ${pageContainerClassname}`}>
      <Transition>{children}</Transition>
    </div>
  );
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isFullWidth: PropTypes.bool
};

PageContainer.defaultProps = {
  isFullWidth: false
};

export default PageContainer;
