export const getEnviromentAPIUrl = () => {
  const ENVIROMENTS = {
    production: process.env.REACT_APP_PROD_API_URL,
    qa: process.env.REACT_APP_QA_API_URL,
    development: process.env.REACT_APP_DEV_API_URL
  };
  return ENVIROMENTS[process.env.REACT_APP_ENV];
};

export const getLocalStorageItem = (name) => {
  return localStorage.getItem(name);
};

export const setLocalStorageItem = (key, value) => {
  return localStorage.setItem(key, value);
};

export const chunkPromiseHandler = async (items, chunkSize, callback) => {
  const offset = chunkSize;
  let i = 0;
  let j = i + offset;
  while (i <= items.length) {
    // eslint-disable-next-line
    await Promise.all(items.slice(i, j).map(callback));
    i = j;
    j += offset;
  }
};
