import PropTypes from 'prop-types';
import logoutIcon from 'assets/icons/icon-logout.svg';
import VivaLogo from './components/VivaLogo';

function Header(props) {
  const { handleAuth } = props;

  return (
    <nav className="bg-primary text-text-alt p-6">
      <div className="flex items-center justify-between">
        <VivaLogo />
        <div className="flex items-center">
          <button type="button" onClick={handleAuth}>
            <img src={logoutIcon} alt="logout" />
          </button>
        </div>
      </div>
    </nav>
  );
}

Header.propTypes = {
  handleAuth: PropTypes.func.isRequired
};

export default Header;
