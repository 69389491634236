import PropTypes from 'prop-types';

import downloadIcon from 'assets/icons/icon-download.svg';
import fileIcon from 'assets/icons/file-icon.svg';
import useSftpHook from 'views/SFTP/SFTP.hook';

function DownloadButton({ fileName }) {
  const { handleDownload } = useSftpHook();
  return (
    <div
      role="button"
      onClick={() => handleDownload(fileName)}
      tabIndex={0}
      onKeyDown={() => handleDownload(fileName)}
      className="flex flex-row pl-6 pr-6 py-4 hover:bg-secondaryLight hover:bg-opacity-80 transition-transform transform hover:text-primary hover:font-bold">
      <img src={fileIcon} alt="open folder" className="mr-2 text-darkGray flex-none" />
      <p className="text-darkGray flex-1 pl-3">{fileName}</p>
      <img src={downloadIcon} alt="open folder" className="mr-2" />
    </div>
  );
}

DownloadButton.propTypes = {
  fileName: PropTypes.string.isRequired
};

export default DownloadButton;
