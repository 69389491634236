import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/toastify.css';

function Toast() {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}

export default Toast;
