export const REGION = 'us-east-1';

export const TOAST_CONFIG = {
  types: {
    error: 'error',
    success: 'success'
  }
};

export const FILES = {
  txt: '.txt',
  pdf: '.pdf',
  doc: '.doc',
  docx: '.docx',
  xls: '.xls',
  xlsx: '.xlsx',
  ppt: '.ppt',
  pptx: '.pptx',
  png: '.png',
  jpg: '.jpg',
  jpeg: '.jpeg',
  gif: '.gif',
  bmp: '.bmp',
  tiff: '.tiff',
  svg: '.svg',
  mp3: '.mp3',
  wav: '.wav',
  ogg: '.ogg',
  flac: '.flac',
  aac: '.aac',
  m4a: '.m4a',
  avi: '.avi',
  mp4: '.mp4',
  mov: '.mov',
  wmv: '.wmv',
  zip: '.zip',
  rar: '.rar',
  tar: '.tar',
  gz: '.gz',
  dmg: '.dmg',
  img: '.img',
  bin: '.bin',
  md: '.md',
  html: '.html',
  htm: '.htm',
  css: '.css',
  js: '.js',
  json: '.json',
  xml: '.xml',
  csv: '.csv',
  tsv: '.tsv',
  sql: '.sql',
  psd: '.psd',
  ai: '.ai',
  eps: '.eps'
};
