import { useCallback } from 'react';
import leftArrowIcon from 'assets/icons/icon-left-arrow.svg';
import useSftpHook from 'views/SFTP/SFTP.hook';
import FolderButton from '../FolderButton';
import DownloadButton from '../DownloadButton';
import IconButton from '../IconButton';

function Viewer() {
  const {
    handlePathChange,
    currentContents,
    isRoot,
    previousPath,
    currentPath,
    handleGoBack,
    handleDownloadAll
  } = useSftpHook();

  const { folders, files } = currentContents;

  const handleNext = useCallback(
    (newPath) => {
      handlePathChange(`${currentPath}/${newPath}`);
    },
    [currentPath, handlePathChange]
  );

  const isOnLoanPackageFolder = currentPath.includes('loanPackages') && files.length > 0;
  const isOnLoanPackageDayFolder =
    currentPath.includes('loanPackages') && currentPath.split('/').length > 4;
  const canShowDownloadAllButton = isOnLoanPackageFolder || isOnLoanPackageDayFolder;
  const folderName = currentPath.split('/').pop();

  return (
    <div className={isRoot ? 'mt-8' : 'p-4'}>
      {!isRoot && (
        <div className="flex flex-col">
          <div id="navigation" className="flex flex-row justify-between align-middle">
            <IconButton
              handleClick={handleGoBack}
              option={previousPath === 'root' ? 'back' : previousPath.split('/').pop()}
              icon={leftArrowIcon}
            />
            {canShowDownloadAllButton && (
              <button
                id="download-all"
                type="button"
                onClick={() => handleDownloadAll()}
                className="flex flex-row align-middle gap-1 my-6 hover:font-extrabold hover:text-primary transition-transform hover:scale-105">
                Download Files
              </button>
            )}
          </div>
          <FolderButton
            option={folderName}
            customClass="py-4 bg-secondary bg-opacity-20 text-primary font-extrabold"
            open
          />
        </div>
      )}
      {folders?.map((item) => (
        <div
          key={`folder-${item}`}
          role="button"
          tabIndex={0}
          className="hover:bg-secondaryLight hover:bg-opacity-80"
          onClick={() => handleNext(item)}
          onKeyDown={() => handleNext(item)}>
          <FolderButton option={item} customClass="py-2 cursor-pointer" />
        </div>
      ))}
      {files?.map((item) => (
        <DownloadButton key={`file-${item}`} fileName={item} />
      ))}
    </div>
  );
}

export default Viewer;
