import React from 'react';
import useSftpHook from 'views/SFTP/SFTP.hook';

function Loader() {
  const { loadingLabel } = useSftpHook();

  const circleCommonClasses = 'h-2.5 w-2.5 bg-current rounded-full bg-secondary';

  return (
    <div className="flex flex-col items-center gap-5">
      <div className="flex">
        <div className={`${circleCommonClasses} mr-1 animate-bounce`} />
        <div className={`${circleCommonClasses} mr-1 animate-bounce200`} />
        <div className={`${circleCommonClasses} animate-bounce400`} />
      </div>
      <div>
        <span className="text-secondary font-bold">{loadingLabel}</span>
      </div>
    </div>
  );
}

export default Loader;
