import { toast as toastify } from 'react-toastify';
import ToastMessage from '../components/ToastMessage';

export const toast = (type, messageObject, autoClose) => {
  return new Promise((resolve) => {
    resolve(
      toastify[type](<ToastMessage {...messageObject} />, {
        autoClose: autoClose || false
      })
    );
  });
};
