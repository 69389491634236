import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import ROUTES from 'common/constants/routes';
import { setLocalStorageItem } from 'common/helper';
import { REGION } from 'common/constants';
import PrivateRoute from 'components/Layout/PrivateRoute';
import NotFound from 'views/NotFound';
import Login from 'views/Login';
import Sftp from 'views/SFTP/SFTP.controller';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  },
  Storage: {
    AWS3: {
      bucket: process.env.REACT_APP_SFTP_FILE_BUCKET_NAME,
      region: REGION
    }
  }
});

function RoutesContainer() {
  const { user, signOut } = useAuthenticator();
  const [isAuth, setIsAuth] = useState(false);

  // handling log out with a state in order to force the redirect
  const handleAuth = () => {
    signOut();
    setIsAuth(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    if (user && !isAuth && user?.attributes) {
      setIsAuth(true);
      setLocalStorageItem('email', user.attributes.email);
      setLocalStorageItem('token', user.signInUserSession.idToken.jwtToken);
    }
  }, [user]);
  // end

  if (user && user?.attributes) {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path={ROUTES.SFTP}
            element={
              <PrivateRoute view={Sftp} handleAuth={handleAuth} attributes={user.attributes} />
            }
          />
          <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
          <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND} replace />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return <Login />;
}

export default RoutesContainer;
