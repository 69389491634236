import PropTypes from 'prop-types';
import Header from 'components/Header';
import PageContainer from 'components/PageContainer';

function PrivateRoute(props) {
  const { view: View, handleAuth, isFullWidth } = props;
  return (
    <>
      <Header handleAuth={handleAuth} />
      <div className="h-[calc(100%-85px)]">
        <PageContainer isFullWidth={isFullWidth}>
          <View {...props} />
        </PageContainer>
      </div>
    </>
  );
}

PrivateRoute.propTypes = {
  view: PropTypes.elementType.isRequired,
  handleAuth: PropTypes.func.isRequired,
  isFullWidth: PropTypes.bool
};

PrivateRoute.defaultProps = {
  isFullWidth: false
};

export default PrivateRoute;
