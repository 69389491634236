import React from 'react';
import ReactDOM from 'react-dom/client';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { Provider } from 'react-redux';
import Toast from 'components/Toast';
import RoutesContainer from './Routes';
import reportWebVitals from './reportWebVitals';
import store from './store';
import './index.css';
import './assets/styles/amplify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AmplifyProvider>
    <Authenticator.Provider>
      <Provider store={store}>
        <RoutesContainer />
        <Toast />
      </Provider>
    </Authenticator.Provider>
  </AmplifyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
