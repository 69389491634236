import PropTypes from 'prop-types';

import folderIcon from 'assets/icons/icon-folder.svg';
import openFolderIcon from 'assets/icons/icon-open-folder.svg';

function FolderButton(props) {
  const { customClass, option, open } = props;

  return (
    <div className={`flex items-center px-6 ${customClass}`}>
      <img src={open ? openFolderIcon : folderIcon} alt="open folder" className="mr-2" />
      <p>{option}</p>
    </div>
  );
}

FolderButton.propTypes = {
  open: PropTypes.bool,
  option: PropTypes.string,
  customClass: PropTypes.string
};

FolderButton.defaultProps = {
  customClass: '',
  option: '',
  open: false
};

export default FolderButton;
