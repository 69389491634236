import PropTypes from 'prop-types';

function IconButton(props) {
  const { handleClick, option, icon } = props;
  return (
    <button type="button" onClick={handleClick} className="flex items-center my-6">
      <img src={icon} alt={`${option} folder`} className="mr-2" />
      <p className="text-black hover:font-extrabold hover:text-primary transition-transform hover:scale-105">
        {option}
      </p>
    </button>
  );
}

IconButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  option: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default IconButton;
