import { SftpProvider } from './SFTP.hook';
import SftpView from './SFTP.view';

function SftpViewController(attributes) {
  return (
    <SftpProvider attributes={attributes}>
      <SftpView attributes={attributes} />
    </SftpProvider>
  );
}

export default SftpViewController;
