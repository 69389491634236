import fileIcon from 'assets/icons/icon-file.svg';
import { FILES } from 'common/constants';
import useSftpHook from '../../SFTP.hook';

function FileUpload() {
  const { handleFileChange, file } = useSftpHook();
  return (
    <div className="flex justify-center items-center w-full">
      <label
        htmlFor="dropzone-file"
        className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-secondary border-dashed cursor-pointer">
        <div className="flex flex-col justify-center items-center pt-5 pb-6 text-sm">
          <img src={fileIcon} alt="Upload File" />

          <p className="text-secondary">Click to upload</p>
          <p className="font-bold">{file?.name ?? ''}</p>
        </div>
        <input
          id="dropzone-file"
          type="file"
          className="hidden"
          accept={Object.values(FILES).join(',')}
          onChange={handleFileChange}
        />
      </label>
    </div>
  );
}

export default FileUpload;
