import vivaLogo from 'assets/icons/viva-logo-white.svg';
import curveBg from 'assets/icons/art-merchant_curve_background.svg';

function Header() {
  return (
    <>
      <div className="bg-primary flex justify-center pt-28 pb-9">
        <img src={vivaLogo} alt="Viva Finance Logo" className="h-14 md:h-24" />
      </div>
      <img src={curveBg} alt="Curve bg" />
    </>
  );
}

export default Header;
