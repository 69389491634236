import { ADD_NOTIFICATION } from 'redux/actionTypes/notification.actionTypes';

const initialStates = {
  notifications: [],
  index: 0
};

const notificationReducer = (state = initialStates, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
        index: action.index
      };

    default:
      return state;
  }
};

export default notificationReducer;
